import TableControler from "components/ModuleElements/Table/TableControler"
import { queryDataByParameters } from "helpers/Utils"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

// a quick way to handle the TableControler Component
// this component is basically a template for modules to use as
// a lot of them work this way but it is not neccessary to use this component
const TableModule = ({
  getData,
  noCreateNew,
  noInitialSort,
  searchKey,
  openInNewTab,
  columns,
  location,
  history,
  firebaseUID,
  clientMetaData,
  refresh,
  openButtonIndex,
  actionButtons,
  shouldQueryDataInParent,
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [shouldUpdate, setShouldUpdate] = useState(0)

  useEffect(() => {
    setLoading(true)
    load()
  }, [refresh])

  const load = async () => {
    if (!shouldQueryDataInParent) {
      const _data = await getData({ firebaseUID, clientMetaData })
      setData(_data)
    } else {
      setShouldUpdate(shouldUpdate + 1)
    }
    setLoading(false)
  }

  const onOpen = ID => {
    if (openInNewTab) {
      const win = window.open(
        `${location.pathname}${location.search}${
          location.search ? "&" : "?"
        }opened=${ID ? encodeURIComponent(ID) : "new"}`,
        "_blank"
      )
      win.focus()
    } else {
      history.push(
        `${location.pathname}${location.search}${
          location.search ? "&" : "?"
        }opened=${ID ? encodeURIComponent(ID) : "new"}`
      )
    }
  }

  const queryData = async params => {
    if (shouldQueryDataInParent) {
      const data = await getData({ ...params, firebaseUID, clientMetaData })

      return data
    }

    const _params = { ...params }

    if (params.searchKeyword) {
      _params.searchBy = { key: searchKey, value: params.searchKeyword }
    }

    return queryDataByParameters(data, _params)
  }

  if (loading) return <div className="loading" />

  return (
    <TableControler
      getData={async params => await queryData(params)}
      topButtons={
        noCreateNew
          ? []
          : [
              {
                label: "Create new",
                show: true,
                onClick: () => onOpen(),
              },
            ]
      }
      openButton={{
        show: true,
        disabled: false,
        onClick: ID => onOpen(ID),
        index: openButtonIndex || 0,
      }}
      columns={columns}
      noInitialSort={noInitialSort}
      noSearch={!searchKey}
      actionButtons={actionButtons}
      shouldUpdate={shouldUpdate}
    />
  )
}

const mapStateToProps = ({ Auth }) => {
  const { client } = Auth

  return { firebaseUID: client?.ID, clientMetaData: client?.MetaData }
}
export default connect(mapStateToProps, {})(withRouter(TableModule))
