export const UserRole = {
  "Super Administrator": 0,
  Administrator: 1,
}

export const defaultLocale = "en"
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
]

export const adminRoot = "/app/"

export const themeColorStorageKey = "__theme_selected_color"
export const themeRadiusStorageKey = "__theme_radius"

// export const eeID = '00000000-0000-0000-0000-000000000000';
// export const eeSecret = '000';
export const APIPath = "https://api.cityx.hr/api/"
export const OcpApimSubscriptionKey = "fbd44b1e1ec249c49abc8668d85c0e4a"
export const Environment = "Production/"

export const defaultOptions = [
  {
    Label: "",
    Type: "Option",
    ID: "option_id_97153215-12523151235-21345234643262",
  },
  {
    Label: "",
    Type: "Option",
    ID: "option_id_513451345345-23623462376879-243563",
  },
  {
    Label: "",
    Type: "Option",
    ID: "option_id_08517235980134/532-6234631614-362",
  },
]

export const pageSizeOptions = [5, 10, 20, 50, 100, 200]
export const countryCodes = [
  {
    name: "AT-Austria",
    value: "AT",
  },
  {
    name: "BE-Belgium",
    value: "BR",
  },
  {
    name: "BG-Bulgaria",
    value: "BG",
  },
  {
    name: "HR-Croatia",
    value: "HR",
  },
  {
    name: "CY-Cyprus",
    value: "CY",
  },
  {
    name: "CZ-Czechia",
    value: "CZ",
  },
  {
    name: "DK-Denmark",
    value: "DK",
  },
  {
    name: "EE-Estonia",
    value: "EE",
  },
  {
    name: "FI-Finland",
    value: "FI",
  },
  {
    name: "FR-France",
    value: "FR",
  },
  {
    name: "DE-Germany",
    value: "DE",
  },
  {
    name: "EL-Greece",
    value: "EL",
  },
  {
    name: "HU-Hungary",
    value: "HU",
  },
  {
    name: "IE-Iceland",
    value: "IE",
  },
  {
    name: "IT-Italy",
    value: "IT",
  },
  {
    name: "LV-Latvia",
    value: "LV",
  },
  {
    name: "LT-Lithuania",
    value: "LT",
  },
  {
    name: "LU-Luxembourg",
    value: "LU",
  },
  {
    name: "MT-Malta",
    value: "MT",
  },
  {
    name: "NL-Netherlands",
    value: "NL",
  },
  {
    name: "PL-Poland",
    value: "PL",
  },
  {
    name: "PT-Portugal",
    value: "PT",
  },
  {
    name: "RO-Romania",
    value: "RO",
  },
  {
    name: "SK-Slovakia",
    value: "SK",
  },
  {
    name: "SI-Slovenia",
    value: "SI",
  },
  {
    name: "ES-Spain",
    value: "ES",
  },
  {
    name: "SE-Sweden",
    value: "SE",
  },
  {
    name: "GB-Great Britain",
    value: "GB",
  },
]

export const defaultFileImage =
  "https://icon-library.com/images/png-file-icon/png-file-icon-20.jpg"

export const quillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["image", "code"],
  ],
}

export const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "indent",
  "align",
  "image",
]

export const imageTypes = ["image/jpeg", "image/png", "image/jpg"]

export const menuItemCMSStatusOptions = [
  {
    ID: "Inactive",
    Name: "Inactive",
  },
  {
    ID: "Active",
    Name: "Active",
  },
  {
    ID: "Admin Only",
    Name: "Admin Only",
  },
]

export const cityStatusOptions = [
  {
    ID: "Inactive",
    Name: "Inactive",
  },
  {
    ID: "Active",
    Name: "Active",
  },
  {
    ID: "Upcoming",
    Name: "Upcoming",
  },
]

export const croatianAlphabet = {
  a: 1,
  b: 2,
  c: 3,
  č: 4,
  ć: 5,
  d: 6,
  đ: 8,
  e: 9,
  f: 10,
  g: 11,
  h: 12,
  i: 13,
  j: 14,
  k: 15,
  l: 16,
  m: 18,
  n: 19,
  o: 21,
  p: 22,
  q: 23,
  r: 24,
  s: 25,
  š: 26,
  t: 27,
  u: 28,
  v: 29,
  w: 30,
  x: 31,
  y: 32,
  z: 33,
  ž: 34,
}

export const clientTypeCategoryIDs = {
  Business: 1,
  "Local government": 2,
}

export const subdomainToAppID = {
  city: 5,
}

export const companyData = {
  Name: "CityX Apps d.o.o.",
  Address: "Ulica Janka Draškovića 21/B",
  OIB: "44810033758",
  IBAN: "HR93 24020061101007330",
  City: "Samobor",
  ZIPCode: "10430",
}

export const noImageImage =
  "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png?w=640"

const defaultApplicationSubdomain = "city"
export const applicationSubdomain = window.location.host.startsWith("localhost")
  ? defaultApplicationSubdomain
  : window.location.host.split(".")[0]
