import TableModule from "components/Modules/Collections/TableModule"
import { getDataFromFirestoreCollection } from "helpers/Firestore"
import React from "react"

const TicketingEventsTable = () => {
  return (
    <TableModule
      getData={async ({ firebaseUID }) => {
        return await getDataFromFirestoreCollection({
          where: [
            {
              key: "Organizer",
              operation: "==",
              value: firebaseUID,
            },
          ],
          path: `City/Modules/DataBase/140/Events`,
        })
      }}
      columns={[{ key: "Title", label: "Title" }]}
      searchKey={"Title"}
    />
  )
}

export default TicketingEventsTable
